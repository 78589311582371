import { Button, Image, List, Modal, Tooltip } from 'antd'
import { displayMessage } from '../../../helpers/messages'
import { useContext } from 'react'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import api from '../../../services/api'
import defaultFileIcon from '../../../assets/files-icon.png'
import styled from '@emotion/styled'

export function ModalReportRiskDetails({
    isOpen,
    onClose,
    reportRisk,
    reloadReportRisks,
    isReportFinished,
}) {
    const { mobile_mode } = useContext(GeneralContext)
    const riskItems = [
        {
            key: '1',
            label: <div className="font-bold">{displayMessage('RISK')}</div>,
            children: <p>{reportRisk?.Risk?.description ?? displayMessage('EMPTY_01')}</p>,
        },
        {
            key: '2',
            label: <p className="font-bold">{displayMessage('RISK_DESCRIPTION')}</p>,
            children: <p>{reportRisk?.Risk?.description ?? displayMessage('EMPTY_01')}</p>,
        },
    ]

    const recommendationItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('RECOMMENDATION')}</p>,
            children: <p>{reportRisk?.Recommendation?.title ?? displayMessage('EMPTY_01')}</p>,
        },
        {
            key: '2',
            label: <p className="font-bold">{displayMessage('RECOMMENDATION_DESCRIPTION')}</p>,
            children: (
                <p>{reportRisk?.Recommendation?.description ?? displayMessage('EMPTY_01')}</p>
            ),
        },
    ]

    const normativeItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('NORMATIVES')}</p>,
        },
    ]

    const affectedPillarItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('AFFECTED_PILLAR')}</p>,
            children: (
                <>
                    <p>{reportRisk.AffectedPillar?.title ?? displayMessage('EMPTY_01')}</p>
                </>
            ),
        },
    ]

    const insuranceItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('INSURANCE_COMPANY')}</p>,
            children: (
                <>
                    <p>{reportRisk.InsuranceCompany?.name ?? displayMessage('EMPTY_01')}</p>
                </>
            ),
        },
    ]

    const controlMesureItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('CONTROL_MESURE')}</p>,
            children: (
                <>
                    <p>{reportRisk.ControlMesure?.name ?? displayMessage('EMPTY_01')}</p>
                </>
            ),
        },
        {
            key: '2',
            label: <p className="font-bold">{displayMessage('CONTROL_MESURE_DESCRIPTION')}</p>,
            children: <p>{reportRisk.ControlMesure?.description ?? displayMessage('EMPTY_01')}</p>,
        },
    ]

    const severityItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('SEVERITY')}</p>,
            children: (
                <>
                    <p>{reportRisk.Severity?.name ?? displayMessage('EMPTY_01')}</p>
                </>
            ),
        },
        {
            key: '2',
            label: <p className="font-bold">{displayMessage('SEVERITY_DESCRIPTION')}</p>,
            children: <p>{reportRisk.Severity?.description ?? displayMessage('EMPTY_01')}</p>,
        },
    ]

    const frequencyItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('FREQUENCY')}</p>,
            children: (
                <>
                    <p>{reportRisk.Frequency?.name ?? displayMessage('EMPTY_01')}</p>
                </>
            ),
        },
        {
            key: '2',
            label: <p className="font-bold">{displayMessage('FREQUENCY_DESCRIPTION')}</p>,
            children: <p>{reportRisk.Frequency?.description ?? displayMessage('EMPTY_01')}</p>,
        },
    ]

    const coverageScaleItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('COVERAGE_SCALE')}</p>,
            children: (
                <>
                    <p>{reportRisk.CoverageScale?.name ?? displayMessage('EMPTY_01')}</p>
                </>
            ),
        },
        {
            key: '2',
            label: <p className="font-bold">{displayMessage('COVERAGE_SCALE_DESCRIPTION')}</p>,
            children: <p>{reportRisk.CoverageScale?.description ?? displayMessage('EMPTY_01')}</p>,
        },
    ]

    const priorityLevelItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('PRIORITY_LEVEL')}</p>,
            children: (
                <>
                    <p>{reportRisk.PriorityLevel?.name}</p>
                </>
            ),
        },
        {
            key: '2',
            label: <p className="font-bold">{displayMessage('CONTROL_MESURE_DESCRIPTION')}</p>,
            children: <p>{reportRisk.PriorityLevel?.description}</p>,
        },
        {
            key: '3',
            label: <p className="font-bold">{displayMessage('COLOR')}</p>,
            children: (
                <div
                    style={{
                        width: 20,
                        height: 20,
                        borderRadius: 10,
                        backgroundColor: reportRisk.PriorityLevel?.color,
                        justifySelf: 'center',
                    }}
                ></div>
            ),
        },
    ]

    const detectionItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('DETECTION')}</p>,
            children: (
                <>
                    <p>{reportRisk.Detection?.description ?? displayMessage('EMPTY_01')}</p>
                </>
            ),
        },
        {
            key: '2',
            label: <p className="font-bold">{displayMessage('DETECTION_DESCRIPTION')}</p>,
            children: <p>{reportRisk.Detection?.description ?? displayMessage('EMPTY_01')}</p>,
        },
    ]

    const workSectorItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('WORK_SECTOR')}</p>,
            children: (
                <>
                    <p>{reportRisk?.WorkSector?.name ?? displayMessage('EMPTY_01')}</p>
                </>
            ),
        },
    ]

    const locationItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('LOCATION')}</p>,
            children: (
                <>
                    <p>{reportRisk.location ?? displayMessage('EMPTY_01')}</p>
                </>
            ),
        },
    ]

    const clientUsersItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('TECHNICAL_MANAGER')}</p>,
            children: (
                <>
                    <p>
                        {reportRisk.ReportRiskClientUsers.length > 0
                            ? reportRisk.ReportRiskClientUsers.map(
                                  rc => `${rc?.ClientUser?.first_name} ${rc?.ClientUser?.last_name}`
                              ).join(', ')
                            : displayMessage('EMPTY_01')}
                    </p>
                </>
            ),
        },
    ]

    const observationsItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('OBSERVATIONS')}</p>,
            children: (
                <>
                    <p>{reportRisk.observations ?? displayMessage('EMPTY_01')}</p>
                </>
            ),
        },
    ]

    const imageDescriptionItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('EVIDENCE_DESCRIPTION')}</p>,
            children: (
                <>
                    <p>{reportRisk.image_description ?? displayMessage('EMPTY_01')}</p>
                </>
            ),
        },
    ]

    const sequenceItems = [
        {
            key: '1',
            label: <p className="font-bold">{displayMessage('SEQUENCE')}</p>,
        },
    ]

    const renderList = (dataSource, emptyText) => {
        return (
            <>
                <List
                    size="small"
                    locale={{ emptyText }}
                    className="rounded p-0 m-0"
                    itemLayout="horizontal"
                    dataSource={dataSource}
                    renderItem={item => (
                        <List.Item className="p-2">
                            <p>{item.description}</p>
                        </List.Item>
                    )}
                />
            </>
        )
    }

    const handleImageDownload = () => {
        try {
            const imageUrl = api.attachments.imageDownload(reportRisk?.image_id)
            const link = document.createElement('a')
            link.href = imageUrl
            link.download = 'downloaded_image.jpg'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        } catch (error) {
            console.log(error)
        }
    }

    const verifyRiskAttachmentType = reportRisk => {
        const fileImageTypes = ['image/png', 'image/jpg', 'image/jpeg']

        if (fileImageTypes.includes(reportRisk?.Attachment?.file_type)) {
            return api.attachments.imageDownload(reportRisk?.image_id)
        } else {
            return defaultFileIcon
        }
    }

    return (
        <Modal
            title={displayMessage('REPORT_RISK_DETAILS')}
            centered
            style={{ padding: 20 }}
            open={isOpen}
            width={1000}
            onCancel={() => onClose(false)}
            footer={[<Button onClick={() => onClose(false)}>{displayMessage('GO_BACK')}</Button>]}
            maskClosable={false}
        >
            <div className="flex w-full flex-col">
                <div className="mt-3">
                    <div
                        className={`text-base flex ${
                            mobile_mode ? 'flex-col' : 'flex-row'
                        } w-full justify-start`}
                    >
                        <div
                            className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                            style={{ width: mobile_mode ? '100%' : '25%' }}
                        >
                            <p style={{ alignSelf: 'center' }}>{riskItems[0].label}</p>
                        </div>
                        <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                            <div className="flex flex-row">
                                <p className="ml-2">{reportRisk?.Risk?.description}</p>
                            </div>
                        </div>
                    </div>

                    <div
                        className={`text-base flex ${
                            mobile_mode ? 'flex-col' : 'flex-row'
                        } w-full justify-start mt-2`}
                    >
                        <div
                            className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                            style={{ width: mobile_mode ? '100%' : '25%' }}
                        >
                            <p style={{ alignSelf: 'center' }}>{normativeItems[0].label}</p>
                        </div>
                        <Tooltip
                            autoAdjustOverflow={true}
                            placement="bottom"
                            title={renderList(
                                reportRisk?.Risk?.Normatives,
                                displayMessage('NO_ONE_NORMATIVE_FOR_THIS_RISK')
                            )}
                            overlayStyle={{ maxWidth: 500 }}
                            overlayInnerStyle={{
                                backgroundColor: 'white',
                                color: 'black',
                                textAlign: 'justify',
                            }}
                        >
                            <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm max-h-[100px] min-h-[50px] w-full overflow-hidden justify-start text-start">
                                <div className="flex flex-row">
                                    {renderList(
                                        reportRisk?.Risk?.Normatives,
                                        displayMessage('NO_ONE_NORMATIVE_FOR_THIS_RISK')
                                    )}
                                </div>
                            </div>
                        </Tooltip>
                    </div>

                    <div
                        className={`text-base flex ${
                            mobile_mode ? 'flex-col' : 'flex-row'
                        } w-full justify-start mt-2`}
                    >
                        <div
                            className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                            style={{ width: mobile_mode ? '100%' : '25%' }}
                        >
                            <p style={{ alignSelf: 'center' }}>{recommendationItems[0].label}</p>
                        </div>
                        <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                            <div className="flex flex-row">
                                <p className="ml-2">
                                    {reportRisk?.Risk?.Recommendation?.description}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`text-base flex ${
                            mobile_mode ? 'flex-col' : 'flex-row'
                        } w-full justify-start mt-2`}
                    >
                        <div
                            className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                            style={{ width: mobile_mode ? '100%' : '25%' }}
                        >
                            <p style={{ alignSelf: 'center' }}>{sequenceItems[0].label}</p>
                        </div>
                        <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                            <div className="flex flex-row">
                                <p className="ml-2">{reportRisk.sequence}</p>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`text-base flex ${
                            mobile_mode ? 'flex-col' : 'flex-row'
                        } w-full justify-start mt-2`}
                    >
                        <div
                            className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                            style={{ width: mobile_mode ? '100%' : '25%' }}
                        >
                            <p style={{ alignSelf: 'center' }}>{priorityLevelItems[0].label}</p>
                        </div>
                        <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                            <div className="flex flex-row">
                                <p className="ml-2">{reportRisk?.PriorityLevel?.name}</p>
                            </div>
                            <div className="flex flex-row">
                                <p className="ml-2">{reportRisk?.PriorityLevel?.description}</p>
                            </div>
                            <div
                                style={{
                                    width: 20,
                                    height: 20,
                                    borderRadius: 10,
                                    backgroundColor: reportRisk.PriorityLevel?.color,
                                    justifySelf: 'center',
                                    marginLeft: '6px',
                                }}
                            ></div>
                        </div>
                    </div>

                    <div
                        className={`text-base flex ${
                            mobile_mode ? 'flex-col' : 'flex-row'
                        } w-full justify-start mt-2`}
                    >
                        <div
                            className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                            style={{ width: mobile_mode ? '100%' : '25%' }}
                        >
                            <p style={{ alignSelf: 'center' }}>{affectedPillarItems[0].label}</p>
                        </div>
                        <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                            <div className="flex flex-row">
                                <p className="ml-2">{reportRisk?.AffectedPillar?.title}</p>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`text-base flex ${
                            mobile_mode ? 'flex-col' : 'flex-row'
                        } w-full justify-start mt-2`}
                    >
                        <div
                            className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                            style={{ width: mobile_mode ? '100%' : '25%' }}
                        >
                            <p style={{ alignSelf: 'center' }}>{insuranceItems[0].label}</p>
                        </div>
                        <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                            <div className="flex flex-row">
                                <p className="ml-2">{reportRisk?.InsuranceCompany?.name}</p>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`text-base flex ${
                            mobile_mode ? 'flex-col' : 'flex-row'
                        } w-full justify-start mt-2`}
                    >
                        <div
                            className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                            style={{ width: mobile_mode ? '100%' : '25%' }}
                        >
                            <p style={{ alignSelf: 'center' }}>{frequencyItems[0].label}</p>
                        </div>
                        <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                            <div className="flex flex-row">
                                <p className="ml-2">{reportRisk?.Frequency?.description}</p>
                            </div>
                        </div>
                    </div>

                    <div
                        className={`text-base flex ${
                            mobile_mode ? 'flex-col' : 'flex-row'
                        } w-full justify-start mt-2`}
                    >
                        <div
                            className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                            style={{ width: mobile_mode ? '100%' : '25%' }}
                        >
                            <p style={{ alignSelf: 'center' }}>{controlMesureItems[0].label}</p>
                        </div>
                        <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                            <div className="flex flex-row">
                                <p className="ml-2">{reportRisk?.ControlMesure?.description}</p>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`text-base flex ${
                            mobile_mode ? 'flex-col' : 'flex-row'
                        } w-full justify-start mt-2`}
                    >
                        <div
                            className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                            style={{ width: mobile_mode ? '100%' : '25%' }}
                        >
                            <p style={{ alignSelf: 'center' }}>{detectionItems[0].label}</p>
                        </div>
                        <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                            <div className="flex flex-row">
                                <p className="ml-2">{reportRisk?.Detection?.description}</p>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`text-base flex ${
                            mobile_mode ? 'flex-col' : 'flex-row'
                        } w-full justify-start mt-2`}
                    >
                        <div
                            className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                            style={{ width: mobile_mode ? '100%' : '25%' }}
                        >
                            <p style={{ alignSelf: 'center' }}>{severityItems[0].label}</p>
                        </div>
                        <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                            <div className="flex flex-row">
                                <p className="ml-2">{reportRisk?.Severity?.description}</p>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`text-base flex ${
                            mobile_mode ? 'flex-col' : 'flex-row'
                        } w-full justify-start mt-2`}
                    >
                        <div
                            className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                            style={{ width: mobile_mode ? '100%' : '25%' }}
                        >
                            <p style={{ alignSelf: 'center' }}>{coverageScaleItems[0].label}</p>
                        </div>
                        <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                            <div className="flex flex-row">
                                <p className="ml-2">{reportRisk?.CoverageScale?.description}</p>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`text-base flex ${
                            mobile_mode ? 'flex-col' : 'flex-row'
                        } w-full justify-start mt-2`}
                    >
                        <div
                            className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                            style={{ width: mobile_mode ? '100%' : '25%' }}
                        >
                            <p style={{ alignSelf: 'center' }}>{workSectorItems[0].label}</p>
                        </div>
                        <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                            <div className="flex flex-row">
                                <p className="ml-2">{workSectorItems[0].children}</p>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`text-base flex ${
                            mobile_mode ? 'flex-col' : 'flex-row'
                        } w-full justify-start mt-2`}
                    >
                        <div
                            className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                            style={{ width: mobile_mode ? '100%' : '25%' }}
                        >
                            <p style={{ alignSelf: 'center' }}>{locationItems[0].label}</p>
                        </div>
                        <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                            <div className="flex flex-row">
                                <p className="ml-2">{locationItems[0].children}</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className={`text-base flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full justify-start mt-2`}>
                    <div className="flex flex-row bg-slate-200 p-4 rounded-l-md" style={{ width: mobile_mode ? '100%' : '25%' }}>
                        <p style={{ alignSelf: 'center' }}>
                            {
                                clientUsersItems[0].label
                            }
                        </p>
                    </div>
                    <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                        <div className="flex flex-row">
                            <p className="ml-2">
                                {
                                    clientUsersItems[0].children
                                }
                            </p>
                        </div>
                    </div>
                </div> */}

                    <div
                        className={`text-base flex ${
                            mobile_mode ? 'flex-col' : 'flex-row'
                        } w-full justify-start mt-2`}
                    >
                        <div
                            className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                            style={{ width: mobile_mode ? '100%' : '25%' }}
                        >
                            <p style={{ alignSelf: 'center' }}>{observationsItems[0].label}</p>
                        </div>
                        <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                            <div className="flex flex-row">
                                <p className="ml-2">{observationsItems[0].children}</p>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`text-base flex ${
                            mobile_mode ? 'flex-col' : 'flex-row'
                        } w-full justify-start mt-2`}
                    >
                        <div
                            className="flex flex-row bg-slate-200 p-4 rounded-l-md"
                            style={{ width: mobile_mode ? '100%' : '25%' }}
                        >
                            <p style={{ alignSelf: 'center' }}>{imageDescriptionItems[0].label}</p>
                        </div>
                        <div className="flex flex-col bg-slate-50 p-4 rounded-r-sm w-full justify-start text-start">
                            <div className="flex flex-row">
                                <p className="ml-2">{imageDescriptionItems[0].children}</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-row w-full h-32 mt-5 justify-center">
                        <div>
                            <div className="text-center ">{displayMessage('CURRENT_EVIDENCE')}</div>
                            <img
                                // src={api.attachments.imageDownload(reportRisk?.image_id)}
                                src={verifyRiskAttachmentType(reportRisk)}
                                // width={130}
                                style={{ height: '100%', width: '100%', cursor: 'pointer' }}
                                onClick={handleImageDownload}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
