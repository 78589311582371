import { useContext, useEffect, useState, useRef } from "react"
import { GeneralContext } from "../../context/GeneralContext/GeneralContext"
import { MultiSelect } from "../../components/selects/MultiSelect"
import { displayMessage } from "../../helpers/messages"
import EActivationStatus from "../../enums/EActivationStatus"
import { SL_Select } from "../../components/selects/Select"
import api from "../../services/api"
import styles, { height } from '../../services/styles'
import { SL_Button } from "../../components/buttons/Button"

import { Bar } from "react-chartjs-2"
import Chart, { scales } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { AuthContext } from "../../context/AuthContext/AuthContext"

const maxRequesterForFilter = 10
const charactersLimitToFetch = 3
const { tc1, tc2, bc1, bc2, bc3 } = styles


export default function DashBoard(){
    const { mobile_mode } = useContext(GeneralContext)
    const { loggedUser, isClientUserAdmin } = useContext(AuthContext)
    // Chart Data
    Chart.register(ChartDataLabels)
    const chartRef = useRef(null)
    useEffect(() => {
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
        };
    }, [chartRef])
    
    // Client and Address Data
    const [selectedClients, setSelectedClients] = useState([])
    const [selectedAddress, setSelectedAddress] = useState([])

    const handleSelectedClient = clientValue => {
        if (!clientValue) {
            setSelectedClients([])
            setSelectedAddress([])
        }
        else{
            setSelectedClients(clientValue)
        }
    }
    const mountClients = async e => {
        try {
            const { data } = await api.clients.listForSelectOptions({
                name: e,
                limit: maxRequesterForFilter,
                statusActivation: EActivationStatus.ATIVO.code,
                user_id: loggedUser?.id
            })
            return data
        } catch (error) {
            console.log(error)
        }
    }
    const loadSelectedClient = async () =>{
        try {
            const {data} = await api.clients.listForSelectOptions({
                user_id: loggedUser?.id,
                limit: maxRequesterForFilter,
                statusActivation: EActivationStatus.ATIVO.code
            })
            setSelectedClients(data)

            const addressFromClients = await api.addresses.list({
                client_user_id: loggedUser?.id
            })
            const addressOptions = addressFromClients?.data.map(e=> ({label:e?.street + ' ' + e?.number, value:e?.id}))
            setSelectedAddress(addressOptions)

        } catch (error) {
            console.log(error)
        }
    }

    const handleSelectedAddresses = (array) => {
        setSelectedAddress(array)
    }
    const mountAddresses = async (street_like) => {
        try {
            const { data } = await api.addresses.list({
                street_like,
                client_user_id: loggedUser?.id
            })
            const options = data.map(e=> ({label:e?.street + ' ' + e?.number, value:e?.id}))
            return options
        } catch (error) {
            console.log(error)
        }
    }

    // Priorities Data
    const [prioritiesOptions, setPrioritiesOptions] = useState([])
    const [selectedPriorities, setSelectedPriorities] = useState([])
    const loadPriorities = async () =>{
        try {
            const {data} = await api.priorityLevels.list()
            const options = data.map(e=> ({label:e?.name, value:e?.id}))
            const optionsOrderedByValue = options.sort((a, b) => b.value - a.value)
            setPrioritiesOptions(optionsOrderedByValue)
        } catch (error) {
            console.log(error)
        }
    }

    // Insurance Data
    const [insuranceOptions, setInsuranceOptions] = useState([])
    const [selectedInsurancies, setSelectedInsurancies] = useState([])
    const loadInsurancies = async () =>{
        try {
            const {data} = await api.insurancies.list({statusActivation: EActivationStatus.ATIVO.code})
            const options = data.map(e=> ({label:e?.name, value:e?.id}))
            setInsuranceOptions(options)
            setSelectedInsurancies(options.map(e=>e?.value))
        } catch (error) {
            console.log(error)
        }
    }

    // Non AudiRiskTypes Data
    const [nonAuditoryRisckTypesOptions, setNonAuditoryRisckTypesOptions] = useState([])
    const [graph01FilterOptionsSlected, setGraph01FilterOptionsSlected] = useState([])
    const handleOnChangeGraph01Filter = e => {
        if (!e || e == []) {
            setGraph01ArrayFiltered([])
            setGraph01FilterOptionsSlected([])            
        }
        else{
            const filteredArray = graph01Array.filter(object => e.includes(object.code))
            setGraph01FilterOptionsSlected(e)
            setGraph01ArrayFiltered(filteredArray)
        }
    }

    const [statusOptions, setStatusOptions] = useState([])
    const [graph02FilterOptionsSlected, setGraph02FilterOptionsSlected] = useState([])
    const handleOnChangeGraph02Filter = e => {
        if (!e || e == []) {
            setGraph02ArrayFiltered([])
            setGraph02FilterOptionsSlected([])            
        }
        else{
            const filteredArray = graph02Array.filter(object => e.includes(object.code))
            setGraph02FilterOptionsSlected(e)
            setGraph02ArrayFiltered(filteredArray)
        }
    }


    useEffect(()=>{
        loadPriorities()
        loadInsurancies()
        loadSelectedClient()
    },[])

    const [graph01Array, setGraph01Array] = useState([])
    const [graph02Array, setGraph02Array] = useState([])
    const [graph03Array, setGraph03Array] = useState([])
    const [graph04Array, setGraph04Array] = useState([])

    const [graph01ArrayFiltered, setGraph01ArrayFiltered] = useState([])
    const [graph02ArrayFiltered, setGraph02ArrayFiltered] = useState([])

    // Comentados para caso algum dia desejem adiconar filtros para esses gráficos
    // const [graph03ArrayFiltered, setGraph03ArrayFiltered] = useState([])
    // const [graph04ArrayFiltered, setGraph04ArrayFiltered] = useState([])


    const loadDashboard = async() =>{
        try {
            const {data} = await api.followupReportRisk.dashboard({
                clients: selectedClients,
                addresses: selectedAddress,
                priorities: selectedPriorities,
                insurancies: selectedInsurancies
            })

            const enumSatatusRes = await api.enums.find('EFollowUpStatus')
            const status = Object.values(enumSatatusRes?.data)
            const graph02FilterOptions = status.map(e=>({label: e.description, value: e.code}))
            setStatusOptions(graph02FilterOptions)
            setGraph02FilterOptionsSlected(graph02FilterOptions)


            const allRisksTypes = await api.riskTypes.list()
            const enumRisksTypes = await api.enums.find('ERiskTypes')
            const nonAuditoryRisksTypes = allRisksTypes.data.filter(e=>e.code != enumRisksTypes.data.AUDIT_DOCUMENTS.code)

            const graph01FilterOptions = nonAuditoryRisksTypes.map(e=>({label: e.name, value: e.code}))
            setNonAuditoryRisckTypesOptions(graph01FilterOptions)
            setGraph01FilterOptionsSlected(graph01FilterOptions)

            //Graph 01 -> Non Auditory by Risk Type
            const graph01 = []
            //Graph 02 -> Non Auditory by Status
            const graph02 = []

            //Graph 03 -> Non Auditory by Priority
            const graph03 = []

            //Graph 04 -> Auditory by Status
            const graph04 = []

            // Fill graphs
            for(const targetType of nonAuditoryRisksTypes){
                graph01.push({
                    code: targetType?.code,
                    name: targetType?.name,
                    counts: []
                })
            }
            for(const targetStatus of status){
                graph02.push({
                    code: targetStatus?.code,
                    name: targetStatus?.description,
                    counts: []
                })

                graph04.push({
                    code: targetStatus?.code,
                    name: targetStatus?.description,
                    counts: 0
                })
            }
            for(const targetPriority of prioritiesOptions){
                graph03.push({
                    code: targetPriority?.value,
                    name: targetPriority?.label,
                    counts: 0
                })
            }

            const missingCompoanyId = 9999
            const not_served_default_code = 8 //Retirado do EFollowUpStatus
            for (const fReporRisk of data){

                const targetRiskType = fReporRisk?.ReportRisk?.Risk?.RiskKind?.RiskActivity?.RiskType?.code
                const targetStatus = fReporRisk.status || enumSatatusRes.data.NOT_SERVED.code || not_served_default_code
                const targetPriority = fReporRisk.ReportRisk.PriorityLevel.id

                const targetInsuranceId = fReporRisk?.ReportRisk?.InsuranceCompany?.id ? fReporRisk.ReportRisk.InsuranceCompany.id : missingCompoanyId
                const targetInsuranceName = fReporRisk?.ReportRisk?.InsuranceCompany?.id ? fReporRisk.ReportRisk.InsuranceCompany.name : 'N/A'
                const targetInsuranceColor = fReporRisk?.ReportRisk?.InsuranceCompany?.id ? fReporRisk.ReportRisk.InsuranceCompany.color : '#000000'

                if (targetRiskType == enumRisksTypes.data.AUDIT_DOCUMENTS.code) {
                    // Add graph04 counts                    
                    const targetG4_object = graph04.filter(e=>e.code == targetStatus)[0]
                    targetG4_object.counts += 1
                }
                else{
                    // Add graph01 counts
                    const targetG1_object = graph01.filter(e=>e.code == targetRiskType)[0]
                    let targetG1_count = targetG1_object.counts.filter(e=>e.insurance_id == targetInsuranceId)[0]
                    const formatedRiskName = fReporRisk.ReportRisk?.recommendation_number + fReporRisk.ReportRisk?.sequence +  ' - ' + fReporRisk.ReportRisk.Risk?.title

                    if (!targetG1_count) {
                        targetG1_object.counts.push({
                            color: targetInsuranceColor,
                            quantity: 1,
                            insurance_id: targetInsuranceId,
                            insurance_name: targetInsuranceName,
                            risk_acronom: [formatedRiskName]
                        })
                    } else{
                        targetG1_count.quantity += 1
                        if (!targetG1_count.risk_acronom.includes(formatedRiskName)) {
                            targetG1_count.risk_acronom.push(formatedRiskName)
                        }
                    }

                    // Add graph02 counts
                    const targetG2_object = graph02.filter(e=>e.code == targetStatus)[0]
                    let targetG2_count = targetG2_object.counts.filter(e=>e.insurance_id == targetInsuranceId)[0]
                    if (!targetG2_count) {
                        targetG2_object.counts.push({
                            color: targetInsuranceColor,
                            quantity: 1,
                            insurance_id: targetInsuranceId,
                            insurance_name: targetInsuranceName,
                            risk_acronom: [formatedRiskName]
                        })
                    } else{
                        targetG2_count.quantity += 1
                        if (!targetG2_count.risk_acronom.includes(formatedRiskName)) {
                            targetG2_count.risk_acronom.push(formatedRiskName)
                        }
                    }

                    // Add graph03 counts
                    const targetG3_object = graph03.filter(e=>e.code == targetPriority)[0]
                    targetG3_object.counts += 1
                }
            }
            setGraph01Array(graph01)
            setGraph01ArrayFiltered(graph01)

            setGraph02Array(graph02)
            setGraph02ArrayFiltered(graph02)

            setGraph03Array(graph03)            
            setGraph04Array(graph04)
            // Comentados para caso algum dia desejem adiconar filtros para esses gráficos
            // setGraph03ArrayFiltered(graph03)
            // setGraph04ArrayFiltered(graph04)
        } catch (error) {
            console.log(error)
        }
    }

    const DashBoard = (graphArray) => {
        const dataset = []
        const uniqueLegendItems = {};
        let countCeiling = 0

        graphArray.forEach((arrayItem, arrayIndex) => {
            let totalCount = 0
            arrayItem.counts.forEach((count, countIndex) => {
                dataset.push({
                    data: graphArray.map((_, index) => (index === arrayIndex ? count.quantity : 0)),
                    backgroundColor: count.color,
                    stack: arrayItem.name,
                    acronom: count?.risk_acronom || 'N/A'
                })
                if (!uniqueLegendItems[count.insurance_name]) {
                    uniqueLegendItems[count.insurance_name] = count.color
                }
                totalCount += count?.quantity
            })
            if (countCeiling < totalCount) { countCeiling = totalCount}
        })

        const legendItems = Object.entries(uniqueLegendItems).map(([name, color]) => ({
            text: name,
            fillStyle: color,
        }))

        const data = {
            labels: graphArray.map(e=>e?.name),
            datasets: dataset
        }

        const options = {
            responsive: true,
            maintainAspectRatio: true,
            animation: false,
            scales: {
                y: {
                    beginAtZero: true,
                    stacked: true, 
                    suggestedMax: countCeiling*1.1
                },
                x: {
                    stacked: true,
                    beginAtZero: true,
                    barPercentage: 0.8,
                    categoryPercentage: 0.7,
                }
            },
            barPercentage: 100,
            categoryPercentage: 0.01,
            plugins: {
                legend: {
                    display: true,
                    onClick: () => {},
                    labels: {
                        generateLabels: () => legendItems,
                        color: bc2
                    }
                },
                tooltip: {
                    enabled: false,
                    callbacks: {
                        label: (context) => {
                            return context.dataset.acronom;
                        }
                    }
                },
                datalabels: {
                    display: true,
                    color: 'black',
                    anchor: 'center',
                    align: 'center',
                    formatter: (value) => (value > 1) ? value : '',
                    font: {
                        weight: 'bold',
                        size: 10,
                    }
                }
            }
        }
        return (
            <Bar data={data} options={options} ></Bar>
        )
    }

    const SimpleVerticalDashBoard = (graphArray) => {
        const colorsArray = ['#006b7c', '#00a3bc', '#28e2ff', '#94f1ff']
        let countCeiling = 0
        const data = {
            labels: graphArray.map(e=>e?.name),
            datasets: [{
                data: graphArray.map(e=>e.counts),
                backgroundColor: graphArray.map((_, index) => colorsArray[index % colorsArray.length])
            }]
        }

        for(const targetGraphArray of graphArray){
            if (targetGraphArray?.counts > countCeiling) { countCeiling = targetGraphArray.counts}
        }

        const options = {
            responsive: true,
            indexAxis: 'y',
            maintainAspectRatio: true,
            animation: false,
            scales: {
                x: {
                    beginAtZero: true,
                    suggestedMax: countCeiling*1.1,

                },
                y: {
                    position: 'left',
                    ticks: {
                        callback: () => {return ''}
                    }
                }
            },
            plugins: {
                legend: {
                    display: false,
                    onClick: () => {}
                },
                tooltip:{
                    enabled: false
                },
                datalabels: {
                    display: true,
                    color: 'black',
                    anchor: 'end',
                    align: 'end',
                    formatter: (value) => value,
                    font: {
                        weight: 'bold',
                        size: 10,
                    }
                }
            }
        }
        return (
            <div className="flex h-full w-full">
                <div style={{display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-evenly', marginBottom: '30px', marginTop: '10px'}}>
                    {graphArray.map(e=>{ 
                        return(
                            <div style={{display:'flex', flex:1, alignItems: 'center'}}>{e?.name}</div>
                        )
                    })}
                </div>
                    <Bar data={data} options={options}></Bar>
            </div>
        )
    }


    return (
        <div className="flex h-full gap-10">
            <div style={{display: 'flex', flex: 1, flexDirection: 'column', gap:'10px'}}> 
                {/* Filtros */}
                <div className="flex flex-col w-full">
                    <p className="mb-2">{displayMessage('SELECT_CLIENTS')}</p>
                    <MultiSelect
                        charactersLimitToFetch={charactersLimitToFetch}
                        mode="multiple"
                        allowClear
                        fetchOptions={async e => mountClients(e)}
                        placeholder={selectedClients?.name || displayMessage('CLIENTS')}
                        disabled={!isClientUserAdmin}
                        onChange={handleSelectedClient}
                        value={selectedClients}
                    />
                </div>

                <div className="flex flex-col w-full">
                    <p className="mb-2">{displayMessage('SELECT_ADDRESS')}</p>
                    <MultiSelect
                        disabled={selectedClients?.length <= 0 || !isClientUserAdmin}
                        charactersLimitToFetch={charactersLimitToFetch}
                        mode="multiple"
                        allowClear
                        fetchOptions={async e => mountAddresses(e)}
                        placeholder={selectedAddress?.name || (displayMessage('STREET')+' ...')}
                        onChange={handleSelectedAddresses}
                        value={selectedAddress}
                    />
                </div>

                <div className="flex flex-col w-full">
                    <p className="mb-2">{displayMessage('SELECT_PRIORITIES')}</p>
                    <SL_Select
                        mode="multiple"
                        listItemHeight={100}
                        value={selectedPriorities}
                        placeholder={displayMessage('PRIORITIES')}
                        onChange={setSelectedPriorities}
                        options={prioritiesOptions}
                        allowClear
                    />
                </div>

                <div className="flex flex-col w-full">
                    <p className="mb-2">{displayMessage('SELECT_INSURANCE_COMPANY')}</p>
                    <SL_Select
                        mode="multiple"
                        listItemHeight={100}
                        value={selectedInsurancies}
                        placeholder={displayMessage('INSURANCE_COMPANIES')}
                        onChange={setSelectedInsurancies}
                        options={insuranceOptions}
                        allowClear
                    />
                </div>

                <div className="flex flex-col w-full">
                    <SL_Button
                        style={{ fontSize: 14, backgroundColor: bc1, width: '100%', margin: 0 }} 
                        onClick={loadDashboard}>
                        {displayMessage('BUILD_DASHBOARD')}
                    </SL_Button>
                </div>
                
            </div>
            <div style={{display: 'flex', flex: 2, flexDirection: 'column', justifyContent: 'space-around'}}> 
                {/* Graph 01 & 02 */}
                <div className="flex flex-col w-full h-[40%] items-center justify-center">
                    <p className="mb-2">{displayMessage('GRPH01_TITLE')}</p>
                    {DashBoard(graph01ArrayFiltered)}
                    {/* Graph01 Filter By */}
                    <SL_Select
                        disabled={graph01Array?.length <= 0}
                        mode="multiple"
                        listItemHeight={100}
                        value={graph01FilterOptionsSlected}
                        placeholder={displayMessage('RISK_TYPES')}
                        onChange={handleOnChangeGraph01Filter}
                        options={nonAuditoryRisckTypesOptions}
                        allowClear
                        className={`flex-1 w-full ml-[60px]`}
                    />
                </div>
                <div className="flex flex-col w-full h-[40%] items-center justify-center">
                    <p className="mb-2">{displayMessage('GRPH02_TITLE')}</p>
                    {DashBoard(graph02ArrayFiltered)}
                    <SL_Select
                        disabled={graph02Array?.length <= 0}
                        mode="multiple"
                        listItemHeight={100}
                        value={graph02FilterOptionsSlected}
                        placeholder={displayMessage('STATUS_TYPES')}
                        onChange={handleOnChangeGraph02Filter}
                        options={statusOptions}
                        allowClear
                        className={`flex-1 w-full ml-[60px]`}
                    />
                </div>
            </div>
            <div style={{display: 'flex', flex: 2, flexDirection: 'column', justifyContent: 'space-around'}}> 
                {/* Graph 03 & 04 */}
                <div className="flex flex-col w-full h-[40%] items-center justify-center">
                    <p className="mb-2">{displayMessage('GRPH03_TITLE')}</p>
                    {SimpleVerticalDashBoard(graph03Array)}
                </div>
                <div className="flex flex-col w-full h-[40%] items-center justify-center">
                    <p className="mb-2">{displayMessage('GRPH04_TITLE')}</p>
                    {SimpleVerticalDashBoard(graph04Array)}
                </div>
                
            </div>
        </div>
    )
}