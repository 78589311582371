import { useState } from 'react'
import { axiosInstance } from '../../services/axiosInstances'
import { AuthContext } from './AuthContext'

export function AuthContextProvider({ children }) {
    const [loggedUser, setLoggedUser] = useState(null)
    const [userType, setUserType] = useState(null)
    const [userPermissions, setUserPermissions] = useState([])
    const [isClientUserAdmin, setIsClientUserAdmin] = useState(false)

    function onChangeLoggedUser(user) {
        if (!user) {
            removeAuthorizationToken()

            // Tratativa de redirect feita dentro do GeneralRoute
            // const refirectRoute = window.location.origin + '/login'
            // window.location.href = refirectRoute

            setTimeout(() => {
                setLoggedUser(null)
            }, 2000)

            return
        }

        setLoggedUser(user)
        setUserType(user.type)
        setIsClientUserAdmin(user?.is_client_user_admin ?? false)
    }

    function setAuthorizationToken(token, mustFormatToken = false) {
        let formatedToken = token

        if (mustFormatToken) {
            formatedToken = 'Bearer ' + token
        }

        axiosInstance.defaults.headers.common.Authorization = formatedToken
        localStorage.setItem('token', formatedToken)
    }

    function getAuthorizationToken() {
        return localStorage.getItem('token')
    }

    function removeAuthorizationToken() {
        axiosInstance.defaults.headers.common.Authorization = null

        localStorage.removeItem('user')
        localStorage.removeItem('token')
    }

    const isAdmin = loggedUser?.isAdmin ?? false

    return (
        <AuthContext.Provider
            value={{
                loggedUser,
                userType,
                setLoggedUser: onChangeLoggedUser,
                userPermissions,
                setUserPermissions,
                isAdmin,
                isClientUserAdmin,
                setAuthorizationToken,
                getAuthorizationToken,
                removeAuthorizationToken,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
